const pathPDF2024 = require('./assets/documents/NH_Broschüre_2024_DE.pdf');
const pathPDF2025 = require('./assets/documents/NH_Broschüre_2025_DE.pdf');
const tileConfig = [
  {
    tileTitle: 'Das Natuco Rezept',
    tileAlt: 'NATUCO Sauce wird gekocht',
    bodyTitle: 'Weil es mit gutem Gefühl am besten schmeckt',
    bodyText: (
      <p>
        Ein leckeres Abendessen für die Liebsten oder ein kleiner Snack in der
        Mittagspause – für eine gute, ausgewogene Mahlzeit muss man nicht ewig
        in der Küche stehen. Wir verwenden nur Zutaten in Bio-Qualität und
        bieten den Genießern von heute einen schnellen und einfachen Weg, gut
        und bewusst zu essen. Mit einer breiten Produktrange von Brühen über
        Saucen und Suppen bis zu Würzmischungen für fixe Gerichte ist NATUCO in
        einer schnellen Welt die moderne Antwort auf den Wunsch nach
        Nachhaltigkeit und Qualität bei bestem Geschmack.
      </p>
    ),
    bodyAlt: 'Vater und Kind beim Kochen',
  },
  {
    tileTitle: 'Bio auf Vorrat',
    tileAlt: 'Frisch geerntete Bio-Karotten',
    bodyTitle: 'Jederzeit lecker Kochen mit Bio auf Vorrat',
    bodyText: (
      <p>
        Das Wichtigste bei einem guten Essen ist der gute Geschmack. Dafür
        braucht es Gemüse, das wie Gemüse schmeckt, das ohne Gentechnik und ohne
        Pestizide wächst und ganz natürlich voller Vitamine und Mineralstoffe
        ist. Wir verwenden genau dieses Bio-Gemüse. Alle unsere Zutaten stammen
        aus kontrolliert ökologischer Landwirtschaft und werden schonend
        getrocknet. Das ist nicht nur gut für Dich, sondern auch für die Umwelt.
        Denn es fördert gesunde Böden, artgerechte Tierhaltung und schützt die
        Natur.
      </p>
    ),
    bodyAlt: 'Tomatenernte auf dem Bio-Hof',
    positionDesktop: 0,
  },
  {
    tileTitle: 'Klimaneutral \n durch CO2-Kompensation',
    tileAlt: 'Grüner Fingerabdruck',
    bodyTitle: (
      <>
        Klimabilanz von Natuco <br />
        aktiv und kontinuierlich verbessern
      </>
    ),
    bodyText: (
      <>
        <p>
          Durch die ständige Optimierung unserer Produktionsprozesse und durch CO2-Kompensationsprojekte
          halten wir unseren CO2-Fußabdruck so gering wie möglich. Ganz ohne CO2-Ausstoß können wir unsere
          NATUCO-Produkte jedoch nicht herstellen. Um die Freisetzung von CO2 auf ein Minimum zu reduzieren,
          gestalten wir unsere Produktion so effizient wie möglich. Wenn Du mehr darüber wissen willst,
          wirf gerne einen Blick in unsere <a href={pathPDF2024} target="_blank">Nachhaltigkeitsbroschüre</a>.
        </p>
        <br />
        <h2>Kontinuierliche CO2-Einsparung in der Produktion</h2>
        <p className='mt-0'>
          Im Jahr 2023 haben wir den CO2-Ausstoß an den NATUCO Herstellungsorten im Vergleich zu 2022 bereits
          um 15,0 % reduziert. In den Jahren 2024 und 2025 werden wir erneut in Photovoltaik investieren und
          damit die bestehenden Kapazitäten deutlich ausbauen. Mehr über unsere <strong>Fortschritte auf dem Weg zu
          mehr Nachhaltigkeit</strong> erfährst du <a href={pathPDF2025} target="_blank">hier</a>.
        </p>
        <h2>Kompensation der Emissionen</h2>
        <p className='mt-0'>
          Die restlichen Emissionen gleichen wir bei NATUCO durch die Unterstützung des Klimaschutzprojektes
          Uganda Safe Water Project aus. Dort erhalten Familien Zugang zu sauberem Trinkwasser - Wasser muss
          also nicht mehr abgekocht werden. Dadurch wird weniger Brennholz benötigt und der CO2-Ausstoß
          nachhaltig reduziert.
        </p>
        <h2>Klimaschonende Produkte</h2>
        <p className='mt-0'>
          Auch unsere NATUCO-Produkte selbst sind sehr klimaschonend, denn pro 100 Gramm Gemüsebrühpulver werden
          nur rund 18 Gramm CO2 freigesetzt. Zum Vergleich: 100 Gramm Butter verursachen rund 238 Gramm CO2.
        </p>
      </>
    ),
    imgNarrow: true,
    bodyAlt: 'Eltern und Kind toben im Garten',
    positionMobile: 1,
  },
  {
    tileTitle: 'Bester Geschmack ohne Zusätze',
    tileAlt: 'Frau probiert NATUCO Rezept',
    bodyTitle: 'Schnelle Küche aus echten Zutaten',
    bodyText: (
      <>
        <p>
          Der Geschmack von NATUCO ist echt, denn wir nutzen Zutaten aus
          ökologischer Landwirtschaft und verzichten auf künstliche Aromen
          genauso wie auf Farb- und Konservierungsstoffe sowie auf
          Geschmacksverstärker. Aus Verantwortung für Mensch und Natur sind alle
          unsere Produkte außerdem palmölfrei. Stattdessen stehen
          Bio-Sonnenblumenöl und Bio-Sheabutter auf unserer Zutatenliste.
        </p>
        <p>
          NATUCO ist nicht einfach nur lecker, sondern bester Geschmack mit
          einem guten Gefühl für Dich und Deine Liebsten.
        </p>
      </>
    ),
    bodyAlt: 'Zwei Männer kochen gemeinsam',
    positionDesktop: 0,
  },
  {
    tileTitle: 'Unsere Produkte',
    tileAlt: 'NATUCO Spaghetti Napoli Würzmischung ',
    bodyTitle: 'Schnell kochen, gut essen.',
    bodyText: (
      <>
        <p>
          Die NATUCO Produkte bieten für jeden Geschmack und jeden Anlass das
          Richtige:
        </p>
        <ul>
          <li>
            <span>
              Mit unseren Suppen ist eine gute Mahlzeit in wenigen Minuten
              fertig.
            </span>
          </li>
          <li>
            <span>
              Unsere Würzmischungen lassen von Bolognese bis Gnocchi-Pfanne alle
              Küchenlieblinge einfach gelingen.
            </span>
          </li>
          <li>
            <span>
              Unsere Brühen geben beim Kochen einfach die natürlich gute Würze.
            </span>
          </li>
          <li>
            <span>
              Mit unseren Saucen-Mischungen ist selbst die Sauce zu Braten
              blitzschnell fertig.
            </span>
          </li>
        </ul>
      </>
    ),
    bodyAlt: 'NATUCO Sortiment Würzmischungen ',
    positionMobile: 1,
  },
  {
    tileTitle: 'Natuco News',
    tileAlt: 'NATUCO News',
    bodyTitle: 'Unser Herz schlägt für Bio – seit 20 Jahren',
    bodyText: (
      <>
        <p>
          Natur Compagnie kennst Du schon, NATUCO aber noch nicht? Das wundert
          uns nicht, denn als Bio-Pionier für Trocken-Convenience-Produkte ist
          Natur Compagnie im Bio-Handel heute bereits eine starke Marke. Wir
          machen uns nun mit NATUCO auf, die Welt der Convenience-Produkte auch
          in weiteren Supermärkten zu verändern. Mit NATUCO kommt bester
          Bio-Geschmack ganz einfach in die Vorratskammer und jederzeit fix auf
          den Tisch.
        </p>
        <p>Natuco – Gutes für morgen, nicht erst seit gestern.</p>
      </>
    ),

    bodyAlt: 'Bio-Produkte im Einkaufswagen',
    positionDesktop: 0,
  },
];

const productConfig = [
  {
    title: 'SPAGHETTI BOLOGNESE',
    alt: 'NATUCO Würz-Mix Spaghetti Bolognese',
    img: 'NATUCO_WuerzMix_SpaghettiBolognese72.png',
    description:
      'Der Klassiker für Klein und Groß: Alle lieben eine gute Bolognese.',
  },
  {
    title: 'HÜHNER SUPPE',
    alt: 'NATUCO Hühner Suppe',
    img: 'NATUCO_Suppe_Huehnernudelsuppe72.png',
    description: 'Schmeckt wie früher bei Oma, ist aber viel schneller fertig.',
  },
  {
    title: 'GEMÜSE BRÜHE',
    alt: 'NATUCO Gemüse Brühe',
    img: 'NATUCO_Gemuesebruehe_klassik_Glas72.png',
    description: 'Als Basis für die nächste Suppe oder einfach zum Würzen.',
  },
  {
    title: 'GNOCCHI GEMÜSE PFANNE',
    alt: 'NATUCO Würz-Mix Gnocchi Gemüse Pfanne',
    img: 'NATUCO_WuerzMix_Gnocchi_Gemuese_Pfanne_72dpi_RGB.png',
    description: `Lust auf Italien-Urlaub in Deiner Küche? Los geht's.`,
  },
  {
    title: 'HACKBRATEN',
    alt: 'NATUCO Würz-Mix Hackbraten',
    img: 'NATUCO_WuerzMix_Hackbraten72.png',
    description: 'Mach aus Hackfleisch, was Du möchtest, aber mach es lecker.',
  },
  {
    title: 'TOMATEN CREME SUPPE',
    alt: 'NATUCO Tomaten Creme Suppe',
    img: 'NATUCO_Suppe_Tomatencremesuppe72.png',
    description:
      'Aus echten Bio-Tomaten und als Vorspeise oder Hauptgericht ein Highlight.',
  },
  {
    title: 'GEMÜSE BRÜHE',
    alt: 'NATUCO Gemüse Brühe',
    img: 'NATUCO_Gemuesebruehe_Wuerfel_frontal72.png',
    description: 'Die gute Würzung, schnell zur Hand und perfekt portioniert.',
  },
  {
    title: 'SAUCE ZU BRATEN',
    alt: 'NATUCO Bratensoße',
    img: 'NATUCO_Dunkle_Sauce_Beutel72.png',
    description: 'Die Königsdisziplin bereitest Du jetzt im Handumdrehen zu.',
  },
  {
    title: 'OFEN GEMÜSE',
    alt: 'NATUCO Würz-Mix Ofen Gemüse',
    img: 'NATUCO_WuerzMix_Ofengemuese_72dpi_RGB.png',
    description:
      'Nach dem Schnippeln und Schneiden ist das Würzen kinderleicht.',
  },
  {
    title: 'ZWIEBEL SUPPE',
    alt: 'NATUCO Zwiebel Suppe',
    img: 'NATUCO_Suppe_Zwiebelsuppe72.png',
    description:
      'Herbst oder nicht, die schmeckt immer und macht gemütliche Abende perfekt.',
  },
  {
    title: 'BRÜHE MIT RIND',
    alt: 'NATUCO Rinder Brühe',
    img: 'NATUCO_Rindererbruehe_klassik_Glas72.png',
    description: 'Die Prise Geschmack für Suppen, Saucen und vieles mehr.',
  },
  {
    title: 'HELLE SAUCE',
    alt: 'NATUCO Helle Soße',
    img: 'NATUCO_Helle_Sauce_Beutel72.png',
    description: 'Alles, wovon grünes Gemüse träumt – Geflügel übrigens auch.',
  },
  {
    title: 'CHILI CON CARNE',
    alt: 'NATUCO Würz-Mix Chili con Carne',
    img: 'NATUCO_WuerzMix_ChiliConCarne72.png',
    description:
      'Der bekannteste Eintopf der Welt schmeckt ab sofort noch natürlicher und besser.',
  },
  {
    title: 'CHAMPIGNON CREME SUPPE',
    alt: 'NATUCO Champignon Creme Suppe',
    img: 'NATUCO_Suppe_Champignoncremesuppe72.png',
    description:
      'Keine Zeit, Pilze zu sammeln, oder keine Saison? Keine Sorge, diese Suppe rettet den Tag.',
  },
  {
    title: 'HÜHNER BRÜHE',
    alt: 'NATUCO Hühner Brühe',
    img: 'NATUCO_Huehnerbruehe_klassik_Glas72.png',
    description: 'Die leckere Mischung für guten Geschmack in jedem Gericht.',
  },
  {
    title: 'RAHM SAUCE',
    alt: 'NATUCO Rahm Sauce',
    img: 'NATUCO_Rahm_Sauce_Beutel72.png',
    description:
      'Der passende Mix für fast alles, was Du mit hellem Fleisch kochen möchtest.',
  },
  {
    title: 'LACHS-SAHNE GRATIN',
    alt: 'NATUCO  Würz-Mix Lachs-Sahne Gratin',
    img: 'NATUCO_WuerzMix_LachsSahne72-min.png',
    description:
      'Mach was Besonderes aus Deinem Lachs – mit dem Bio-Würz-Mix geht das ganz fix.',
  },
  {
    title: 'BUCHSTABEN SUPPE',
    alt: 'NATUCO Buchstaben Suppe',
    img: 'NATUCO_Suppe_Buchstaben72-min.png',
    description:
      'Gelingt immer und macht Spaß. Darauf geben wir Dir unser Wort.',
  },
  {
    title: 'RINDER BRÜHE',
    alt: 'NATUCO Rinder Brühe',
    img: 'NATUCO_Rinderbruehe_Wuerfel_frontal72-min.png',
    description:
      'Die Würfel-Variante bringt die richtige Würze schnell in jedes Gericht.',
  },
  {
    title: 'CURRY SAUCE',
    alt: 'NATUCO Curry Sauce',
    img: 'NATUCO_Currysauce_Beutel72-min.png',
    description:
      'Und einfach so schmeckt Reis und Huhn wie eine indische Spezialität.',
  },
  {
    title: 'RAHMGESCHNETZELTES',
    alt: 'NATUCO Würz-Mix Rahmgeschnetzeltes',
    img: 'NATUCO_WuerzMix_Rahmgeschnetzeltes_72dpi_RGB-min.png',
    description:
      'Mit dem kräftigen Würz-Mix schüttelst Du den süddeutschen Klassiker ganz einfach aus dem Ärmel.',
  },
  {
    title: 'BLUMENKOHL BROCCOLI SUPPE',
    alt: 'NATUCO Bluemnkohl Broccoli Suppe',
    img: 'NATUCO_Suppe_Blumenkohl_Broccoli72-min.png',
    description:
      'Lust auf viel Gemüse ganz schnell? Hier kommt die Suppe für die kalten Tage.',
  },
  {
    title: 'HÜHNER BRÜHE',
    alt: 'NATUCO Hühner Brühe',
    img: 'NATUCO_Huehnerbruehe_Wuerfel_frontal72.png',
    description:
      'Die leckere Portion Würze für mehr Geschmack in jedem Gericht.',
  },
  {
    title: 'SAUCE HOLLANDAISE',
    alt: 'NATUCO Sauce Hollandaise',
    img: 'NATUCO_Hollandaise_Beutel72-min.png',
    description: 'Natürlich ist Bio die beste Wahl zur Spargelzeit.',
  },
  {
    title: 'SPAGHETTI NAPOLI',
    alt: 'NATUCO Würz-Mix Spaghetti Napoli',
    img: 'NATUCO_WuerzMix_SpaghettiNapoli72-min.png',
    description:
      'Einfach lecker, die richtige Würze für eine gelungene Portion Spaghetti Napoli.',
  },
  {
    title: 'LINSEN SUPPE',
    alt: 'NATUCO Linsen Suppe',
    img: 'NATUCO_Suppe_Linsen72-min.png',
    description: `Schnell aufwärmen an einem kalten Tag, unsere Linsensuppe macht's möglich.`,
  },
  {
    title: 'SAUCE ZU BRATEN',
    alt: 'NATUCO Sauce zu Braten',
    img: 'NATUCO_Sauce_zuBraten_Dose-min.png',
    description:
      'Endlich gibt es immer genug Sauce zum Braten und sie schmeckt.',
  },
  {
    title: 'KRÄUTER SAUCE',
    alt: 'NATUCO Kräuter Sauce',
    img: 'NATUCO_Krauetersauce_Beutel72-min.png',
    description: `Lust auf Italien-Urlaub in Deiner Küche? Los geht's.`,
  },
  {
    title: 'NUDEL-SCHINKEN GRATIN',
    alt: 'NATUCO Würz-Mix Nudel-Schinken Gratin',
    img: 'NATUCO_WuerzMix_NudelSchinken72-min.png',
    description: 'Ganz einfach ein neues Lieblingsgericht zaubern.',
  },
  {
    title: 'FRÜHLINGS SUPPE',
    alt: 'NATUCO Frühlings Suppe',
    img: 'NATUCO_Suppe_Fruehlingssuppe72-min.png',
    description:
      'Zu jeder Jahreszeit eine gute Wahl. Und jetzt blitzschnell auf dem Tisch.',
  },
  {
    title: 'HELLE SAUCE',
    alt: 'NATUCO Helle Sauce',
    img: 'NATUCO_Helle_Sauce_Dose-min.png',
    description:
      'Wichtig ist eine gute Basis und von der hast Du jetzt immer genug im Vorratsschrank.',
  },
  {
    title: 'GULASCH',
    alt: 'NATUCO Würz-Mix Gulasch',
    img: 'NATUCO_WuerzMix_Gulasch72-min.png',
    description:
      'Mit einem guten Schuss Würz-Mix wird aus Fleisch in Sauce ein perfektes Gulasch.',
  },
  {
    title: 'LASAGNE AL FORNO',
    alt: 'NATUCO Würz-Mix Lasagne al Forno',
    img: 'NATUCO_WuerzMix_Lasagne72-min.png',
    description:
      'Eine gute Lasagne macht alle glücklich, da sollte man beim Geschmack auf Nummer sicher gehen.',
  },
  {
    title: 'KÜRBIS CREME SUPPE',
    alt: 'NATUCO Kürbis Creme Suppe',
    img: 'NATUCO_Suppe_Kuerbiscreme72.png',
    description:
      'Keine Zeit, den Kürbis zu schneiden? Haben wir schon erledigt. Einfach aufgießen und fertig.',
  },
  {
    title: 'RAHM SAUCE',
    alt: 'NATUCO Rahm Sauce',
    img: 'NATUCO_Rahm_Sauce_Dose-min.png',
    description:
      'Die feine Rahmsauce, die man immer zur Hand hat, macht schnelles Kochen auch noch einfach.',
  },
  {
    title: 'TOMATEN SAUCE',
    alt: 'NATUCO Tomaten Sauce',
    img: 'NATUCO_Tomatensauce_Beutel72-min.png',
    description:
      'Zu Nudeln, mit frischem Gemüse – eine gute Tomatensauce gibt es im Handumdrehen dazu.',
  },
];

export { tileConfig, productConfig };
